// CheckBalanceComponent.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Input } from 'antd';
import { useGetAssetsMutation } from '../services/localApi';
import { assetColumns } from './common';
import './common.css';


const CheckBalanceComponent = () => {
    const [textBoxAssets, setTextBoxAssets] = useState('');
    const [getAssets, { data: responseGetAssets, getAssetError }] = useGetAssetsMutation();

    // const handleCheckAssetsTextBox = (e) => {
    //     setTextBoxAssets(e.target.value);
    //   };
    const handleButtonCheckAssets = async () => {
      let inputAssets = ['USDT', 'BTC', 'ETH', 'BNB'];
      if (textBoxAssets !== '') {
        const tmp = textBoxAssets.split(',');
        inputAssets = inputAssets.concat(tmp);
      }
    // eslint-disable-next-line
      await getAssets({ assets: inputAssets, user: localStorage.getItem('access_token') });
    };

    return (
      <div>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
          <div style={{ width: '100px' }}> Balance of : </div>
          <input type="text" value={textBoxAssets} onChange={(e) => setTextBoxAssets(e.target.value)} />
          <Button type="primary" onClick={handleButtonCheckAssets}> Check </Button>
        </div> */}
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '10px', position: 'relative' }}>
          <div style={{ width: '100px' }}> Balance of : </div>
          <input style={{ marginLeft: '30px', width: '150px' }} type="text" value={textBoxAssets} onChange={(e) => setTextBoxAssets(e.target.value)} />
          <div style={{ position: 'absolute', right: '0' }}>
            <Button className="normal-button" onClick={handleButtonCheckAssets}> Check </Button>
          </div>
        </div>
        {responseGetAssets
              && (
              <div>

                <Table
                  columns={assetColumns}
                  dataSource={responseGetAssets}
                  size="small"
                  style={{ marginTop: '20px' }}
                  pagination={false}
                />
              </div>
)}

      </div>
    );
  };

export default CheckBalanceComponent;
