// CheckBalanceComponent.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Input } from 'antd';
import { useGetSymbolMutation } from '../services/localApi';
import { checkSymbolColumns } from './common';

import './common.css';

const CheckSymbolSpreadComponent = () => {
    const [textBoxCheckSymbol, setTextBoxCheckSymbol] = useState('');
    const [getSymbol, { data: responseCheckSymbol, checkSymbolError }] = useGetSymbolMutation();

    // const handleCheckSymbolTextBox = (e) => {
    //     setTextBoxCheckSymbol(e.target.value);
    //   };
    const handleButtonCheckSymbol = async () => {
      // eslint-disable-next-line
      
      await getSymbol({ symbol: textBoxCheckSymbol, user: localStorage.getItem('access_token') });
    };
    return (
      <div>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
          <div style={{ width: '100px' }}>Spread of :</div>
          <input type="text" value={textBoxCheckSymbol} onChange={(e) => { setTextBoxCheckSymbol(e.target.value); }} />
          <Button type="primary" onClick={handleButtonCheckSymbol}> Check </Button>
        </div> */}
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '10px', position: 'relative' }}>
          <div style={{ width: '100px' }}>Spread of :</div>
          <input style={{ marginLeft: '30px', width: '150px' }} type="text" value={textBoxCheckSymbol} onChange={(e) => { setTextBoxCheckSymbol(e.target.value); }} />
          <div style={{ position: 'absolute', right: '0' }}>
            <Button className="normal-button" onClick={handleButtonCheckSymbol}> Check </Button>
          </div>
        </div>
        {responseCheckSymbol
                && (
                <div>
                  {/* Assets: {JSON.stringify(assets)} */}
                  <Table
                    columns={checkSymbolColumns}
                    dataSource={responseCheckSymbol}
                    size="small"
                    style={{ marginTop: '20px' }}
                    pagination={false}
                  />
                </div>
)}

      </div>
    );
  };

export default CheckSymbolSpreadComponent;
