import React, { useState, useEffect } from 'react';
import {
 Button, Menu, Typography, Avatar,
} from 'antd';
import { Link } from 'react-router-dom';
import {
 HomeOutlined, MoneyCollectOutlined, BulbOutlined, FundOutlined, MenuOutlined,
} from '@ant-design/icons';

import icon from '../images/zdt.png';


const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState(true);
  const [screenSize, setScreenSize] = useState(undefined);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 1200) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);
  return (
    <div>
      {/* <NavButton title="Menu" customFunc={handleActiveMenu} color="blue" /> */}
      <div className="logo-container">
        <Avatar src={icon} size="large" />
        <Typography.Title level={2} className="logo"><Link to="/">ZDT</Link></Typography.Title>

      </div>
      {activeMenu && (
      <Menu theme="dark">
        <Menu.Item icon={<HomeOutlined />}>
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item icon={<HomeOutlined />}>
          <Link to="/positions">Positions</Link>
        </Menu.Item>
        <Menu.Item icon={<HomeOutlined />}>
          <Link to="/spreads">Spreads</Link>
        </Menu.Item>
        {/* <Menu.Item icon={<MoneyCollectOutlined />}>
          <Link to="/exchanges">Exchanges</Link>
        </Menu.Item> */}
        <Menu.Item icon={<BulbOutlined />}>
          <Link to="/news">News</Link>
        </Menu.Item>
      </Menu>
      )}
    </div>
  );
};

export default Navbar;
