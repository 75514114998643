import React from 'react';

export const bColumns = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
    },
    {
        title: 'B_size',
        dataIndex: 'B_size',
        className: 'binance-background',
        sorter: (a, b) => a.B_size - b.B_size,
    },
    {
        title: 'B_USD',
        dataIndex: 'B_USD',
        className: 'binance-background',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.B_USD - b.B_USD,
    },
    {
        title: 'B_PnL',
        dataIndex: 'B_PnL',
        className: 'binance-background',
    },
    {
        title: 'B_fr',
        dataIndex: 'B_fr',
        className: 'binance-background',
        render: (value) => (
          <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
            {value}
          </span>
        ),
    },
    {
        title: 'B_liq',
        dataIndex: 'B_liq',
        className: 'binance-background',
    },
];

export const hColumns = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
    },
    {
        title: 'H_size',
        dataIndex: 'H_size',
        className: 'bybit-background',
        sorter: (a, b) => a.H_size - b.H_size,
    },
    {
        title: 'H_USD',
        dataIndex: 'H_USD',
        className: 'bybit-background',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.H_USD - b.H_USD,
    },
    {
        title: 'H_PnL',
        dataIndex: 'H_PnL',
        className: 'bybit-background',
    },
    {
        title: 'H_fr',
        dataIndex: 'H_fr',
        className: 'bybit-background',
        render: (value) => (
          <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
            {value}
          </span>
        ),
    },
    {
        title: 'H_liq',
        dataIndex: 'H_liq',
        className: 'bybit-background',
    },
];

export const assetColumns = [
    {
        title: 'S',
        dataIndex: 's',
    },
    {
        title: 'SPOT',
        dataIndex: 'spot',
    },
    {
        title: 'ISO',
        dataIndex: 'iso',
    },
    {
        title: 'CROSS',
        dataIndex: 'cross',
    },
    {
        title: 'PERP',
        dataIndex: 'perp',
    },
    {
        title: 'EARN',
        dataIndex: 'earn',
    },
];

export const checkSymbolColumns = [
    {
        title: 'Pair',
        dataIndex: 's',
    },
    {
        title: 'SHORT',
        dataIndex: 'short',
    },
    {
        title: 'LONG',
        dataIndex: 'long',
    },
    {
        title: 'POS',
        dataIndex: 'position',
    },
    {
        title: 'FR',
        dataIndex: 'fr',
    },
];

export const fundingRateColumns = [
    {
        title: 'Time',
        dataIndex: 'time',
    },
    {
        title: 'B',
        dataIndex: 'B',
    },
    {
        title: 'H',
        dataIndex: 'H',
    },
];

export const earnLoanColumns = [
    {
        title: 'Coin',
        dataIndex: 'asset',
    },
    {
        title: 'APY',
        dataIndex: 'latestAPR',
    },
];

