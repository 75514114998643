// src/Login.js
import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useStateContext } from './app/ContextProvider';

const Login = () => {
    const SERVER = process.env.NODE_ENV === 'production' ? 'https://zero-delta.io/api/authenticate' : 'http://localhost:5555/api/authenticate';
    const { setIsLoggedIn, setUser } = useStateContext();
    const history = useHistory();
    const UNAUTHORIZED_USER_MESSAGE = 'Please login with authorized user';
    const [message, setMessage] = useState('Login by google email');

    useEffect(() => {
        if (history.location?.state?.from) {
        setMessage('Login by google email');
        }
    }, [history.location]);

    const responseGoogle = async (response) => {
      if (response.credential) {
        // console.log('Google login success, verifying user');
        // console.log('server is', SERVER);
        // const credentialResponseDecoded = jwtDecode(response.credential);
        // console.log(credentialResponseDecoded);
        const { credential } = response;
        // console.log(credential);
        try {
          const res = await axios.post(SERVER, {
            //  eslint-disable max-len
            token: credential,
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          // console.log('User verified', res);
          const { accessToken, refreshToken } = res.data;
          localStorage.setItem('access_token', accessToken);
          localStorage.setItem('refresh_token', refreshToken);
          setIsLoggedIn(true);
          setUser(accessToken);
          const redirectTo = history.location.state?.from || '/';
          history.push(redirectTo);
        } catch (error) {
          // console.error('Error verifying user', error);
          setIsLoggedIn(false);
          setUser(null);
          setMessage(UNAUTHORIZED_USER_MESSAGE);
        }
      } else {
        setIsLoggedIn(false);
        setUser(null);
        setMessage('Login by google email');
      }
    };

  return (
    <div>
      <h1 style={{ color: message === UNAUTHORIZED_USER_MESSAGE ? 'red' : 'black' }}>{message}</h1>
      <div style={{ width: 'flex' }}>
        <GoogleLogin
          buttonText="Login with Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy="single_host_origin"
        />
      </div>
    </div>
  );
};

export default Login;
