// CheckBalanceComponent.js
import React, { useEffect, useState, useRef } from 'react';
import { Table, Button, Dropdown, Menu, Spin } from 'antd';
import { Line } from 'react-chartjs-2';
import { DownOutlined } from '@ant-design/icons';
import { useGetHistoricalSpreadMutation } from '../services/localApi';
import './common.css';

// unknow problem
// https://github.com/reactchartjs/react-chartjs-2/issues/648
// use React.memo to solve
const options = {
  aspectRatio: 4, // width / height = 4
  plugins: {
    legend: false,
  },
};
const HistoricalSpreadGraph = React.memo(({ data }) => <Line data={data} options={options} />);

const HistoricalSpreadComponent = () => {
  const [getHistoricalSpread] = useGetHistoricalSpreadMutation();
  const [selectedPair, setSelectedPair] = useState('AH');
  const [selectedDays, setSelectedDays] = useState('3');
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [historicalSpread, setHistoricalSpread] = useState(null);
  const [loading, setLoading] = useState(false);

  const pairMenu = (
    <Menu onClick={(e) => setSelectedPair(e.key)}>
      <Menu.Item key="AB">AB</Menu.Item>
      <Menu.Item key="AG">AG</Menu.Item>
      <Menu.Item key="AH">AH</Menu.Item>
      <Menu.Item key="BG">BG</Menu.Item>
      <Menu.Item key="BH">BH</Menu.Item>
      <Menu.Item key="GH">GH</Menu.Item>
    </Menu>
  );

  const daysMenu = (
    <Menu onClick={(e) => setSelectedDays(e.key)}>
      <Menu.Item key="1">1</Menu.Item>
      <Menu.Item key="3">3</Menu.Item>
      <Menu.Item key="7">7</Menu.Item>
    </Menu>
  );

  const handleSymbolChange = (e) => {
    // You can manage the input here without affecting the graph
    setSelectedSymbol(e.target.value);
  };
  // const historicalSpreadRef = useRef(null);

  const handleButtonCheckHistoricalSpread = async () => {
    setLoading(true);
      setHistoricalSpread(null);
      if (selectedSymbol !== '') {
          // eslint-disable-next-line
          const temp = await getHistoricalSpread({ user: localStorage.getItem('access_token'), symbol: selectedSymbol, pair: selectedPair, days: selectedDays});
          // console.log(temp);
          if (temp.data) {
          // console.log(temp.data.ts);
              const lineData = {
                  labels: temp.data.ts,
                  datasets: [
                      {
                      label: 'Long',
                      data: temp.data.long,
                      borderColor: 'green',
                      fill: false,
                      borderWidth: 0.5,
                      pointRadius: 0.1,
                      },
                      {
                      label: 'Short',
                      data: temp.data.short,
                      borderColor: 'red',
                      fill: false,
                      borderWidth: 0.5,
                      pointRadius: 0.1,
                      },
                  ],
              };
              setHistoricalSpread(lineData);
          }
      }
      setLoading(false);
    };

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
          <div style={{ width: '120px' }}>Spread history : </div>
          {/* <input type="text" value={textBoxCheckFundingRate} onChange={handleCheckFundingRateTextbox} /> */}
          <Dropdown style={{ margin: '10px' }} overlay={pairMenu}>
            <Button>
              {selectedPair}
            </Button>
          </Dropdown>

          <input style={{ marginLeft: '10px' }} type="text" value={selectedSymbol} onChange={handleSymbolChange} />
          <div style={{ marginLeft: '10px' }}>
            in
            <Dropdown overlay={daysMenu}>
              <Button>
                {selectedDays}
              </Button>
            </Dropdown>
            days
          </div>
          <Button className="normal-button" style={{ marginLeft: '10px' }} type="primary" onClick={handleButtonCheckHistoricalSpread}> Check </Button>
          {loading && (
            <Spin style={{ marginLeft: '10px' }} />
          )}

        </div>
        {historicalSpread
                && (
                  <div>
                    <HistoricalSpreadGraph data={historicalSpread} />
                  </div>
)}
      </div>
    );
};

export default HistoricalSpreadComponent;
