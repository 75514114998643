import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';

import { useStateContext } from '../app/ContextProvider';

const News = () => {
  const {
 isLoggedIn, setIsLoggedIn, user, setUser,
} = useStateContext();
  if (!isLoggedIn) {
     return (
       <div>
         google login demo

         <GoogleLogin
           buttonText="Login with Google"
           onSuccess={responseGoogle}
           onFailure={responseGoogle}
         />
         ;
       </div>
  );
  }

  return (
    <div>
      login success
    </div>
  );
};

export default News;
