import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// const headers = {
//   'Content-Type': 'application/json',
// };

// const createRequest = (url) => ({ url, headers: headers });
// const createRequest = (url, userData) => ({
//     url,
//     headers: userData ? { Authorization: `Bearer ${userData}` } : {},
//   });
const createRequest = (url, method = 'GET', userData, body = null) => {
  const requestConfig = {
    url,
    headers: userData ? { Authorization: `Bearer ${userData}` } : { 'Content-Type': 'application/json' },
  };
  if (method !== 'GET') {
    requestConfig.method = method;
    requestConfig.body = JSON.stringify(body);
  }
  return requestConfig;
};

export const localApi = createApi({
  reducerPath: 'localApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.NODE_ENV === 'production' ? 'https://zero-delta.io' : 'http://localhost:5555' }),
  endpoints: (builder) => ({

    getBPositions: builder.query({
      query: () => createRequest('/api/get-B-positions'),
    }),

    getHPositions: builder.query({
      query: () => createRequest('/api/get-H-positions'),
    }),

    getPositions: builder.query({
      query: (body) => createRequest('/api/get-positions', 'GET', body),
    }),

    getAssets: builder.mutation({
      query: (body) => createRequest('/api/get-assets', 'POST', null, body),
    }),

    getSymbol: builder.mutation({
      query: (body) => createRequest('/api/get-symbol', 'POST', null, body),
    }),
    getFr: builder.mutation({
      query: (body) => createRequest('/api/get-fr', 'POST', null, body),
    }),

    getSpreads: builder.query({
      query: (body) => createRequest('/api/get-spreads', 'GET', body),
    }),

    // Add refresh token endpoint
    refreshToken: builder.mutation({
      query: (body) => createRequest('/api/refresh-token', 'POST', null, body),
    }),

    postTrade: builder.mutation({
      query: (body) => createRequest('/api/post-trade', 'POST', null, body),
    }),

    listTrade: builder.mutation({
      query: (body) => createRequest('/api/list-trade', 'POST', null, body),
    }),

    resetTrade: builder.mutation({
      query: (body) => createRequest('/api/reset-trade', 'POST', null, body),
    }),

    stopTrade: builder.mutation({
      query: (body) => createRequest('/api/stop-trade', 'POST', null, body),
    }),

    getHistoricalSpread: builder.mutation({
      query: (body) => createRequest('/api/get-historical-spread', 'POST', null, body),
    }),

    getEarnLoanAPY: builder.mutation({
      query: (body) => createRequest('/api/get-earn-loan-apy', 'POST', null, body),
    }),

    // getSymbol: builder.mutation({
    //   query: (data) => {
    //     const { body, userData } = data;
    //     return createRequest('/api/get-symbol', 'POST', userData, body)
    //   },
    // }),

    getDummyData: builder.query({
      query: () => createRequest('/api/my-endpoint'),
    }),



  }),
});

export const {
  useGetBPositionsQuery, useGetHPositionsQuery, useGetPositionsQuery,
  useGetAssetsMutation, useGetSymbolMutation, useGetFrMutation,
  useGetDummyDataQuery, useGetSpreadsQuery, useRefreshTokenMutation,
  usePostTradeMutation, useListTradeMutation, useResetTradeMutation,
  useStopTradeMutation, useGetHistoricalSpreadMutation, useGetEarnLoanAPYMutation } = localApi;
