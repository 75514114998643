import React, { useEffect, useState } from 'react';
import millify from 'millify';
import { Modal, Select, Table, Button, Input, notification } from 'antd';

import HTMLReactParser from 'html-react-parser';
import { useStateContext } from '../app/ContextProvider';

// import { GoogleLogin } from 'react-google-login';
import { useResetTradeMutation, useListTradeMutation, useStopTradeMutation, useGetSpreadsQuery } from '../services/localApi';
import Loader from './Loader';
import useTokenRefresh from '../services/useTokenRefresh';
import { assetColumns, checkSymbolColumns, fundingRateColumns } from './common';
import CheckBalanceComponent from './CheckBalanceComponent';
import CheckFundingRateHistoryComponent from './CheckFundingRateHistoryComponent';
import CheckSymbolSpreadComponent from './CheckSymbolSpreadComponent';
import TradeComponent from './TradeComponent';
import TradeListComponent from './TradeListComponent';
import HistoricalSpreadComponent from './HistoricalSpreadComponent';
import AssetControlComponent from './AssetControlComponent';

const { Option } = Select;

const Spreads = () => {
  useTokenRefresh();
  const [lastUpated, setLastUpdated] = useState('');
  const [sortOrder, setSortOrder] = useState(null);
  const [shortThreshold, setShortThreshold] = useState(0.021); // Initialize threshold value
  const [longThreshold, setLongThreshold] = useState(-0.1); // Initialize threshold value

  // const [getListTrade, { data: responseListTrade, listTradeError }] = useListTradeMutation();
  // const [resetTrade, { data: responseResetTrade, resetTradeError }] = useResetTradeMutation();
  // const [stopTrade, { data: responseStopTrade, stopTradeError }] = useStopTradeMutation();
  // const [currentTradeList, setCurrentTradeList] = useState(null);

  const {
      isLoggedIn, setIsLoggedIn, user, setUser,
    } = useStateContext();
    const [initialLoad, setInitialLoad] = useState(true);
    const {
      data, isFetching, refetch, error,
    } = useGetSpreadsQuery(localStorage.getItem('access_token'));

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 30000); // 30 seconds

    if (initialLoad && !isFetching && data) {
      setInitialLoad(false);
    }

    if (data) {
      setLastUpdated(new Date().toLocaleString('en-GB'));
    }

    return () => clearInterval(intervalId);
  }, [refetch, isFetching, data, initialLoad]);

  const floatSorter = (a, b, key) => {
    const aValue = parseFloat(a[key]);
    const bValue = parseFloat(b[key]);
    const aIsFloat = !Number.isNaN(aValue);
    const bIsFloat = !Number.isNaN(bValue);
    if (aIsFloat && bIsFloat) {
      // return sortOrder === 'ascend' ? aValue - bValue : bValue - aValue;
      return aValue - bValue;
    } if (aIsFloat) {
      return sortOrder === 'ascend' ? 999 : -999;
    } if (bIsFloat) {
      return sortOrder === 'ascend' ? -999 : 999;
    }
      return 0; // both are strings or non-numbers
  };

  const renderShort = (value) => {
    const numericValue = parseFloat(value);
    const isHigherShortThreshold = !Number.isNaN(numericValue) && numericValue > shortThreshold;
    // const shouldColorIt = !Number.isNaN(numericValue) && numericValue < shortThreshold;

    return (
      <span
        style={{
          color: isHigherShortThreshold ? 'green' : 'black',
          fontWeight: isHigherShortThreshold ? 'bold' : 'normal',
        }}
      >
        {value}
      </span>
    );
  };

  const renderLong = (value) => {
    const numericValue = parseFloat(value);
    const isLowerLongThreshold = !Number.isNaN(numericValue) && numericValue < longThreshold;
    return (
      <span
        style={{
          color: isLowerLongThreshold ? 'red' : 'black',
          fontWeight: isLowerLongThreshold ? 'bold' : 'normal',
        }}
      >
        {value}
      </span>
    );
  };

  const handleShortThresholdChange = (e) => {
    setShortThreshold(parseFloat(e.target.value));
  };
  const handleLongThresholdChange = (e) => {
    setLongThreshold(parseFloat(e.target.value));
  };

  const spreadsColumns = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
      },
      {
        title: 'AB_short',
        dataIndex: 'AB_short',
        sorter: (a, b) => floatSorter(a, b, 'AB_short'),
        render: renderShort,
      },
      {
        title: 'AB_long',
        dataIndex: 'AB_long',
        sorter: (a, b) => floatSorter(a, b, 'AB_long'),
        render: renderLong,
      },
      {
        title: 'AG_short',
        dataIndex: 'AG_short',
        sorter: (a, b) => floatSorter(a, b, 'AG_short'),
        render: renderShort,
      },
      {
        title: 'AG_long',
        dataIndex: 'AG_long',
        sorter: (a, b) => floatSorter(a, b, 'AG_long'),
        render: renderLong,
      },
      {
        title: 'AH_short',
        dataIndex: 'AH_short',
        sorter: (a, b) => floatSorter(a, b, 'AH_short'),
        render: renderShort,
      },
      {
        title: 'AH_long',
        dataIndex: 'AH_long',
        sorter: (a, b) => floatSorter(a, b, 'AH_long'),
        render: renderLong,
      },
      {
        title: 'BG_short',
        dataIndex: 'BG_short',
        sorter: (a, b) => floatSorter(a, b, 'BG_short'),
        render: renderShort,
      },
      {
        title: 'BG_long',
        dataIndex: 'BG_long',
        sorter: (a, b) => floatSorter(a, b, 'BG_long'),
        render: renderLong,
      },
      {
        title: 'BH_short',
        dataIndex: 'BH_short',
        sorter: (a, b) => floatSorter(a, b, 'BH_short'),
        render: renderShort,
      },
      {
        title: 'BH_long',
        dataIndex: 'BH_long',
        sorter: (a, b) => floatSorter(a, b, 'BH_long'),
        render: renderLong,
      },
      {
        title: 'GH_short',
        dataIndex: 'GH_short',
        sorter: (a, b) => floatSorter(a, b, 'GH_short'),
        render: renderShort,
      },
      {
        title: 'GH_long',
        dataIndex: 'GH_long',
        sorter: (a, b) => floatSorter(a, b, 'GH_long'),
        render: renderLong,
      },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    setSortOrder(sorter.order);
  };


  return (
    <div className="tables-container">
      <div style={{ marginRight: '20px', flex: '0.75', minWidth: '300px' }}>
        <TradeComponent />
        {/* <AssetControlComponent /> */}
        <CheckBalanceComponent />
        <CheckSymbolSpreadComponent />
        <CheckFundingRateHistoryComponent />

      </div>

      <div style={{ flex: '2' }}>

        <HistoricalSpreadComponent />
        <TradeListComponent />
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px' }}>
          <div style={{ width: '120px' }}>Spread short :</div>
          <input style={{ marginLeft: '20px' }} type="number" value={shortThreshold} onChange={handleShortThresholdChange} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
          <div style={{ width: '120px' }}>Spread long :</div>
          <input style={{ marginLeft: '20px' }} type="number" value={longThreshold} onChange={handleLongThresholdChange} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', color: 'blue', marginTop: '5px' }}>
          {'Last updated :'}
          {lastUpated}
        </div>

        {initialLoad && isFetching && <Loader />}
        {(
          // <div style={{ display: 'flex', flex: '1', flexWrap: 'wrap', backgroundColor: '#001529 !important', color: '#ffffff !important' }}>
          <div>
            <div style={{ marginTop: '20px' }}>
              <Table
                columns={spreadsColumns}
                dataSource={data}
                pagination={false}
                size="small"
                onChange={handleTableChange}
              />
            </div>
          </div>
          )}

      </div>
    </div>
  );
};

export default Spreads;
