// CheckBalanceComponent.js
import React, { useEffect, useState } from 'react';
import { Modal, Select, Table, Button, Input, Radio, notification } from 'antd';
import { usePostTradeMutation, useGetSymbolMutation } from '../services/localApi';




// const { Option } = Select;

const TradeComponent = () => {
  const [postTrade, { data: resopnsePostTrade, postTradeError }] = usePostTradeMutation();


  const [isVisible, setIsVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [symbol, setSymbol] = useState('');
  const [amountUSD, setAmountUSD] = useState('');
  const [isolated, setIsolated] = useState(false);
  const [bidask, setBidask] = useState('');
  const handleClick = (title) => {
      setDialogTitle(title);
      setIsVisible(true);
    };

  const handleOk = async () => {
    setIsVisible(false); // Close the dialog
    try {
      await postTrade({ user: localStorage.getItem('access_token'), side: dialogTitle, symbol, amountUSD, isolated, bidask }).unwrap(); // Using unwrap to get the response directly
      // Check if postTrade was successful
      notification.success({
        message: 'Success',
        description: `Post ${ dialogTitle } successfully.`,
      });
    } catch (error) {
      // Handle error here
      notification.error({
        message: 'Error',
        description: `Unable to post ${ dialogTitle } order !! `,
      });
    }
    // finally {
    //   setIsVisible(false); // Close the dialog
    // }
  };

  const handleCancel = () => {
    setIsVisible(false);
  };
  const onChangeIsolated = (e) => {
    setIsolated(e.target.value);
  };

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px' }}>
        <Button danger onClick={() => handleClick('AB_Short')} style={{ fontWeight: 'bold' }}> AB_Short </Button>
        <Button onClick={() => handleClick('AB_Long')} style={{ borderColor: 'green', fontWeight: 'bold', color: 'green' }}> AB_Long </Button>
        <Button danger onClick={() => handleClick('AH_Short')} style={{ fontWeight: 'bold' }}> AH_Short </Button>
        <Button onClick={() => handleClick('AH_Long')} style={{ borderColor: 'green', fontWeight: 'bold', color: 'green' }}> AH_Long </Button>

        <Modal
          title={dialogTitle}
          visible={isVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
            <div style={{ width: '120px' }}> Symbol : </div>
            <Input style={{ margin: '5px' }} value={symbol} onChange={(e) => setSymbol(e.target.value)} />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
            <div style={{ width: '120px' }}> Amount USD : </div>
            <Input style={{ margin: '5px' }} type="number" placeholder="leave blank to run out current position" value={amountUSD} onChange={(e) => setAmountUSD(e.target.value)} />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
            <div style={{ width: '120px' }}> Bid ask : </div>
            <Input style={{ margin: '5px' }} type="number" value={bidask} onChange={(e) => setBidask(e.target.value)} />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
            <div style={{ width: '120px' }}> Isolated : </div>
            <Radio.Group onChange={onChangeIsolated} value={isolated}>
              <Radio value>True</Radio>
              <Radio value={false}>False</Radio>
              <Radio value={null}>None</Radio>
            </Radio.Group>
          </div>
        </Modal>

      </div>
    );
  };

export default TradeComponent;
